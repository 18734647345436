<template>
  <main class="page" id="home">
    <hero></hero>
    <about></about>
    <services></services>
    <contact></contact>
    <vfooter></vfooter>
    <social :class="{ __active: scroll > 150 }"></social>
  </main>
</template>

<script>
import hero from './home/hero'
import about from './home/about'
import services from './home/services'
import contact from './home/contact'
import vfooter from './home/vfooter'
import social from './home/social'
export default {
  name: 'home',
  props: ['scroll'],
  components: {
    hero,
    about,
    services,
    contact,
    vfooter,
    social
  }
}
</script>
