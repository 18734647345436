<template>
  <section id="hero">
    <div class="wrapper">
      <div class="content">
        <logo></logo>
        <h3>Nós podemos ajudar na gestão do seu negócio ou evento.</h3>
        <h4 class="__thin">Deixe seu e-mail e em breve entraremos em contato.</h4>
        <form @submit.prevent="">
          <input placeholder="Seu nome" type="" value="" name="" id=""/>
          <input placeholder="Seu e-mail" type="" value="" name="" id=""/>
          <button type="submit">ENVIAR</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import logo from '../../../public/img/logo.svg'
export default {
  name: 'hero',
  data () {
    return {
      name: null,
      email: null
    }
  },
  components: {
    logo
  }
}
</script>
