<template>
  <footer id="mainFooter">
    <section>
      <img src="/img/logo.png" alt="">
    </section>
  </footer>
</template>
<script>
export default {
  name: 'vfooter',
  data () {
    return {
    }
  },
  components: {
  }
}
</script>
