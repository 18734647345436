<template>
  <section id="social">
    <a href="https://www.facebook.com/mini.casilhero/" target="_blank">
      <facebook></facebook>
    </a>
    <a href="https://www.instagram.com/mini.casilhero/" target="_blank">
      <instagram></instagram>
    </a>
    <a href="https://www.linkedin.com/company/casilhero" target="_blank">
      <linkedin></linkedin>
    </a>
  </section>
</template>

<script>
import facebook from '../../../public/img/icons/facebook-f-brands.svg'
import instagram from '../../../public/img/icons/instagram-brands.svg'
import linkedin from '../../../public/img/icons/linkedin-in-brands.svg'
export default {
  name: 'social',
  components: {
    facebook,
    instagram,
    linkedin
  }
}
</script>
