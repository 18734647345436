<template>
  <div id="app">
    <vheader :scroll="scroll"></vheader>
    <transition name="fade" mode="out-in">
      <router-view :scroll="scroll"></router-view>
    </transition>
  </div>
</template>

<script>
import vheader from './components/common/vheader.vue'
export default {
  name: 'app',
  data () {
    return {
      scroll: 0
    }
  },
  beforeMount () {
  },
  mounted () {
    window.addEventListener('scroll', e => {
      this.scroll = this.getBodyScrollTop()
    })
  },
  methods: {
    getBodyScrollTop () {
      const el = document.scrollingElement || document.documentElement
      return el.scrollTop
    }
  },
  components: {
    vheader
  }
}
</script>
<style lang="scss">
  @import "scss/main.scss";
</style>
