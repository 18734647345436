<template id="">
  <section id="contact">
    <div class="wrapper">
      <div class="content">
        <span class="sectionTitleBar"></span>
        <h3>CONTATO</h3>
        <form>
          <input placeholder="Nome" type="text" value="" name="name" id=""/>
          <input placeholder="Email" type="email" value="" name="email" id=""/>
          <input placeholder="Telefone" type="phone" value="" name="phone" id=""/>
          <textarea placeholder="Mensagem" name="message" id=""></textarea>
          <button>ENVIAR</button>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
// import jump from 'jump.js'
export default {
  name: 'contact',
  data () {
    return {
    }
  },
  methods: {
    contact () {
    }
  },
  components: {
  }
}
</script>
