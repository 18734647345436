<template>
  <section id="services">
    <div class="wrapper">
      <div class="content">
        <span class="sectionTitleBar"></span>
        <h3>NOSSOS SERVIÇOS</h3>
        <p class="__large">Soluções sob medida para as suas necessidades.</p>
        <article class="grid" id="mini">
          <aside>
            <img src="/img/background/mini@2x.jpg" alt="Logo Mini"/>
          </aside>
          <main>
            <img src="/img/logos/logo_mini@2x.png" alt=""/>
            <p>O MINI é uma ferramenta online para gerenciamento de inscrições, frequências e certificações automáticas. Com uma interface moderna, limpa e de fácil entendimento, o sistema se destaca pela praticidade de uso tanto da comissão organizadora, quanto dos participantes do evento.</p>
            <p>A ferramenta também conta com personalizações para cada necessidade docliente. A programação do evento, as informações dos palestrantes, as opções de emitir avisos a grupos ou individualmente, loja online, gerenciamento de hospedagem, opções de sorteios e mapas, são apenas algumas das dezenas de funções que o MINI também oferece.</p>
            <button>CLIQUE AQUI E CONHEÇA MAIS O MINI</button>
          </main>
        </article>
        <article class="grid" id="cobalt">
          <main>
            <img src="/img/logos/logo_cobalt@2x.png" alt=""/>
            <p>Sistema de gerenciamento de pagamentos e emissão de boletos para pequenas empresas, condomínios, comissões de formatura.</p>
            <p>Nesta ferramenta, o cliente pode cadastrar e gerenciar pagamentos únicos ou periódicos, programar o envio automático de avisos de cobranças, além de emitir vários tipos de relatórios para facilitar o controle financeiro. Tudo numa interface simples e intuitiva, tornando o processo mais simples. Ideal para condomínios, academias, clubes, entre outros.</p>
            <button>EM BREVE</button>
          </main>
          <aside>
            <img src="/img/background/cobalt@2x.jpg" alt="Logo Cobalt"/>
          </aside>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'services'
}
</script>
