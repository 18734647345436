<template>
  <header id="mainHeader" :class="{ __solid: scroll > 150 }">
    <section id="banner">
      <div class="wrapper">
        <article>
          <img src="/img/icons/envelope-solid.png" alt=""/>
          <a href="mailto:contato@casilhero.com.br">contato@casilhero.com.br</a>
        </article>
        <article>
          <img src="/img/icons/phone-solid.png" alt=""/>
          <a href="tel:5573999684354">(73) 9 9968-4354</a>
        </article>
        <article>
          <a href="https://www.facebook.com/mini.casilhero/" target="_blank">
            <img src="/img/icons/facebook-square-brands.png" alt=""/>
          </a>
          <a href="https://www.instagram.com/mini.casilhero/" target="_blank">
            <img src="/img/icons/instagram-brands.png" alt=""/>
          </a>
          <a href="https://www.linkedin.com/company/casilhero" target="_blank">
            <img src="/img/icons/linkedin-brands.png" alt=""/>
          </a>
        </article>
      </div>
    </section>
    <section id="desktop">
      <div class="wrapper">
        <div class="logoContainer">
          <img src="/img/logo_black.png" alt=""/>
        </div>
        <nav>
          <span @click="!jumping ? j('hero'):false">HOME</span>
          <span @click="!jumping ? j('about'):false">SOBRE A GENTE </span>
          <span @click="!jumping ? j('services'):false">SERVIÇOS</span>
          <span @click="!jumping ? j('contact'):false">CONTATO</span>
        </nav>
      </div>
    </section>
    <section class="wrapper" id="mobile">
    </section>
  </header>
</template>

<script>
// import { Slide } from 'vue-burger-menu'
import jump from 'jump.js'
export default {
  name: 'vheader',
  props: ['scroll'],
  data () {
    return {
      jumping: false
    }
  },
  mounted () {
  },
  methods: {
    j (target) {
      this.jumping = true
      // this.$refs.mobile.$children[0].closeMenu()
      jump(`#${target}`, {
        duration: 1000,
        offset: -124,
        callback: () => {
          this.jumping = false
        }
      })
    }
  },
  components: {
    // Slide
  }
}
</script>
