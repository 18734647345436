<template>
  <section id="about">
    <div class="wrapper">
      <div class="content">
        <span class="sectionTitleBar"></span>
        <h3>SOBRE A EMPRESA</h3>
        <p>
          <strong>
            A Casilhero Soluções WEB é uma empresa de Tecnologia da Informação voltada para a área de desenvolvimento web com a missão de tornar toda a experiência do cliente mais simples e rápida.
          </strong>
        </p>
        <p>Fundada em 2015 por estudantes da Universidade Estadual de Santa Cruz, na cidade de Ilhéus-BA, a Casilhero nasceu da necessidade de facilitar a gestão dos eventos acadêmicos organizados pelo Núcleo de Inovação Tecnológica da UESC (NIT UESC).</p>
        <p>Sempre em busca de atualização, a empresa conta com profissionais dinâmicos e tem como diferencial a personalização dos serviços para atender exatamente a necessidade do cliente.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'about'
}
</script>
